import React from "react";
import { Link } from "gatsby";
// import { useLayoutEffect } from "react";
// import { useMediaQuery } from "react-responsive";

import Button from "components/Button";
import BookIcon from "assets/icons/book.svg";
import CircleDownIcon from "assets/icons/circle-down.svg";
import StoreIcon from "assets/icons/store.svg";
import ChromiumLogo from "assets/logos/chromium.png";
import HopHillsImg from "assets/images/hop-hills.svg";
import ShineImg from "assets/images/shine.svg";
import TraceResistantImg from "assets/images/trace-resistant.svg";
import { AvailableSwapsPanel } from "components";
// import MutilHopsImg from "assets/backgrounds/multi-hops.png";
import "./security.info.section.scss";

const platform = require("platform");

const Index = () => {
  // const desktop = useMediaQuery({ minWidth: 768 }); //  < lg

  // useLayoutEffect(() => {
  //   const dom = document.querySelector(".container");
  //   if (desktop && dom) {
  //     const style = window.getComputedStyle
  //       ? getComputedStyle(dom, null)
  //       : dom.currentStyle;
  //     const marginX = style.margin.split(" ").pop();

  //     dom.style.marginRight = 0;
  //     dom.style.width = `calc(${style.width} + ${marginX})`;
  //     dom.style.maxWidth = `calc(${style.maxWidth} + ${marginX})`;
  //   }
  // }, [desktop]);

  const handleReadHowItWorks = () => {};
  const handleReadNode = () => {};
  const handleDownloadApp = () => {};

 // need to add a Headline for this:
 // "This level of privacy didn’t exist. So we built it."


  const renderMultipleHops = () => (
    <div className="multiple-hops panel">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-sm-12 col-md-7 pe-0 pe-md-5 p-0">
            <div
              className="label"
              data-sal="slide-up"
              data-sal-duration="2000"
              data-sal-delay="0"
              data-sal-easing="ease"
            >
              SECURE AND ANONYMOUS ACCESS TO THE GLOBAL INTERNET
            </div>
            <div
              className="title mt-3"
              data-sal="slide-up"
              data-sal-duration="2000"
              data-sal-delay="200"
              data-sal-easing="ease"
            >
              This level of privacy didn’t exist. So we built it.
              <img className="deco" src={HopHillsImg} alt="hop hills" />
            </div>
            <div
              className="subtitle mt-3"
              data-sal="slide-up"
              data-sal-duration="2000"
              data-sal-delay="400"
              data-sal-easing="ease"
            >
              More anonymous and secure than any VPN or dVPN, MASQ makes your traffic trace-resistant over 
              3+ hops, constantly refreshing routes to decentralized servers and exit points worldwide.
            </div>
          
          {/* need call-outs with icons here
          Decentralized servers are spun up into MASQ Neighborhoods worldwide
          Traffic is encrypted and sent over 3 or more hops
          Routes & Exit Points are auto-refreshed when idle
          MASQ is traceless */}

            <div
              className="mt-4"
              data-sal="slide-up"
              data-sal-duration="2000"
              data-sal-delay="600"
              data-sal-easing="ease"
            >
              <Link to="/protocol" target="_blank">
                <Button
                  preIcon={BookIcon}
                  label="HOW MASQ WORKS"
                  handleClick={handleReadHowItWorks}
                />
              </Link>
            </div>
          </div>
          {/* <div className="col-sm-12 col-md-5 p-0">
            <img src={MutilHopsImg} alt="multi-hop" width={"100%"} />
          </div> */}
        </div>
      </div>
    </div>
  );

  const renderTraceResistant = () => (
    <div className="trace-resistant panel">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-7 pe-0 pe-md-5 p-0">
            <div
              className="label"
              data-sal="slide-up"
              data-sal-duration="2000"
              data-sal-delay="0"
              data-sal-easing="ease"
            >
              NO ADS. NO TRACKERS. NO BORDERS.
            </div>
            <div
              className="title mt-3 d-flex flex-wrap justify-content-center justify-content-sm-start"
              data-sal="slide-up"
              data-sal-duration="2000"
              data-sal-delay="200"
              data-sal-easing="ease"
            >
{/* //              <img
//               className="me-2"
//              src={TraceResistantImg}
//             alt="trace resistant"
//              /> */}
              Access content and ideas from anywhere in the world.
            </div>
            <div
              className="subtitle mt-3"
              data-sal="slide-up"
              data-sal-duration="2000"
              data-sal-delay="400"
              data-sal-easing="ease"
            >
              We believe life is better when people can live without restriction. Connect to MASQ, hop
              across the borderless internet, and experience the world from many perspectives.
            </div>
            <div
              className="mt-4"
              data-sal="slide-up"
              data-sal-duration="2000"
              data-sal-delay="600"
              data-sal-easing="ease"
            >
            <Link to="/protocol" target="_blank">
                <Button
                  preIcon={BookIcon}
                  label="HOW MASQ WORKS"
                  handleClick={handleReadHowItWorks}
                />
            </Link>
            </div>
{/* //              <div className="d-flex flex-wrap mt-5 justify-content-center justify-content-sm-start">
//                <a
//                  target="_blank"
//                  rel="noreferrer"
//                  // href={`/download/${window.platform.os.family.toLocaleLowerCase()}`}
//                  href={`/download/${platform.os.family.toLocaleLowerCase()}`}
//                >
//                  <Button
//                    preIcon={CircleDownIcon}
//                    label="DOWNLOAD MASQ DVPN BROWSER"
//                    handleClick={handleDownloadApp}
//                    className="me-0 me-sm-2 my-2"
//                  />
//                </a>
//                <Button
//                  preIcon={ChromiumLogo}
//                  label="chromium"
//                  handleClick={() => {}}
//                  fill={false}
//                  className="chromium my-2"
//                />
//              </div>
//              <div className="mt-3">
//                <span className="tip">
//                  // For {window.platform.os.toString()} or later.
//                  For {platform.os.toString()} or later.
//               </span>
//             </div> */}
          </div>
        </div>
      </div>
    </div>
  );

  const renderGetPaid = () => (
    <div className="get-paid panel">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-7 pe-0 pe-md-5 p-0">
            <div
              className="label"
              data-sal="slide-up"
              data-sal-duration="2000"
              data-sal-delay="0"
              data-sal-easing="ease"
            >
              EARN WHILE YOU SLEEP
            </div>
            <div
              className="title mt-3"
              data-sal="slide-up"
              data-sal-duration="2000"
              data-sal-delay="200"
              data-sal-easing="ease"
            >
              Share your internet connection with MASQ and earn crypto
              <img className="deco" src={ShineImg} alt="shine img" />
            </div>
            <div
              className="subtitle mt-3"
              data-sal="slide-up"
              data-sal-duration="2000"
              data-sal-delay="400"
              data-sal-easing="ease"
            >
              Share your connection with MASQ users worldwide and earn MASQ’s ERC-20 utility token,
              powered by Polygon. Relay nodes earn and exit nodes earn even more. Either way, the
              higher your bandwidth, the more potential to earn.
            </div>
            <div
              className="mt-4"
              data-sal="slide-up"
              data-sal-duration="2000"
              data-sal-delay="600"
              data-sal-easing="ease"
            >
              <Link to="/runnode">
                <Button
                  preIcon={StoreIcon}
                  label="HOW TO RUN A MASQ NODE"
                  handleClick={handleReadNode}
                />
              </Link>
              {/* <div className="get-paid_SwapsPanel"><AvailableSwapsPanel /></div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
  return (
    <div className="DVPN_SecurityInfo text-center text-sm-start">
      <div>{renderMultipleHops()}</div>
      <div>{renderTraceResistant()}</div>
      <div>{renderGetPaid()}</div>
    </div>
  );
};

export default Index;
